<template>
  <div class="maintenance-item">
    <div class="maintenance-item-update">
      <div class="title-line">
        <div class="title-name">{{ historyItem.name }}</div>
        <div class="posted-at">
          {{ historyItemStart }}
        </div>
      </div>
      <nl2br tag="div" class-name="message" :text="historyItem.description"/>
    </div>
  </div>
</template>
<script>

import Nl2br from 'vue-nl2br'
import getUserLocale from 'get-user-locale'

export default {
  components: {
    Nl2br
  },
  props: {
    historyItem: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: false,
      type: Object
    }
  },

  computed: {
    historyItemStart () {
      const locale = this.statusPageLanguage?.date_and_time_language?.type ?? getUserLocale()
      const shortDate = new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      })

      return shortDate.format(new Date(this.historyItem.start))
    }
  }
}
</script>
<style scoped>
.maintenance-item {
  padding: 30px;
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 20px;

  .maintenance-item-update {
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }

    .title-line {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      @media (max-width: 360px) {
        display: block;
      }

      .title-name {
        font-weight: 600;
        margin-right: 10px;
        max-width: 405px;
      }

      .posted-at {
        font-weight: 500;
        font-size: 14px;
        color: rgba(25, 34, 29, 0.5);
        white-space: nowrap;
      }
    }
  }
</style>
