<template>
  <div class="IncidentStatus" :class="[status]">
    {{ statusText }}
  </div>
</template>

<script>
export default {
  props: {
    status: {
      required: true,
      type: String
    },
    statusPageLanguage: {
      required: false
    }
  },

  computed: {
    statusText () {
      if (this.statusPageLanguage && this.statusPageLanguage['text_incident_status_' + this.status]) {
        return this.statusPageLanguage['text_incident_status_' + this.status]
      }
      return this.status.charAt(0).toUpperCase() + this.status.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .IncidentStatus {
    @include inline-flex-center;

    width: 100px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;

    border-radius: 18px;
    margin-right: 30px;

    flex-shrink: 0;

    &.exploring {
      background-color: rgba(25, 34, 29, 0.4);
    }

    &.identified {
      background-color: rgba(25, 34, 29, 0.4);
    }

    &.update {
      background-color: rgba(25, 34, 29, 0.4);
    }

    &.resolved {
      background-color: rgba(25, 34, 29, 1);
    }
  }
</style>
