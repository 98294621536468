<template>
  <div class="IncidentsItem">
    <div class="title d-none">{{ incident.title }}</div>
    <incident-update v-for="update in incident.updates"
                     :key="update.id"
                     :update="update"
                     :title="incident.title"
                     :status-page-language="statusPageLanguage"
    />
  </div>
</template>

<script>
import IncidentUpdate from './IncidentsItemUpdate.vue'

export default {
  components: {
    IncidentUpdate
  },

  props: {
    incident: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .IncidentsItem {
    padding: 30px;
    background: #f8f8f8;
    border-radius: 8px;
    margin-bottom: 20px;

    .title {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
</style>
