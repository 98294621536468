<template>
  <div class="IncidentsItemUpdate">
    <incident-status :status="update.status" :status-page-language="statusPageLanguage"/>

    <div class="status-update">
      <div class="title-line">
        <div class="title">{{ title }}</div>
        <div class="posted-at">
          {{ createdAt }}
        </div>
      </div>

      <nl2br tag="div" class-name="message" :text="update.message" />
    </div>
  </div>
</template>

<script>
import IncidentStatus from '@/components/Common/IncidentStatus.vue'
import Nl2br from 'vue-nl2br'
import getUserLocale from 'get-user-locale'

export default {
  components: {
    IncidentStatus,
    Nl2br
  },

  props: {
    update: {
      required: true,
      type: Object
    },
    title: {
      required: true,
      type: String
    },
    statusPageLanguage: {
      required: false
    }
  },

  methods: {
    shortDate (timestamp) {
      const locale = (this.statusPageLanguage && this.statusPageLanguage.date_and_time_language?.type) ? this.statusPageLanguage.date_and_time_language?.type : getUserLocale()
      const shortDate = new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      })

      return shortDate.format(new Date(timestamp * 1000))
    }
  },

  computed: {
    createdAt () {
      if (this.update.date) {
        return this.shortDate(Date.parse(this.update.date) / 1000)
      } else {
        return this.shortDate(this.update.created_at)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .IncidentsItemUpdate {
    display: flex;
    //margin-bottom: 40px;

    // .header {
    //   display: flex;
    //   justify-content: space-between;
    //   margin-bottom: 10px;
    // }

    @media (max-width: 500px) {
      display: block;
    }

    + .IncidentsItemUpdate {
      margin-top: 30px;
    }

    &:hover .header .edit {
      display: block;
    }

    .message {
      //margin-bottom: 10px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;

      @media (max-width: 500px) {
        font-size: 16px;
      }
    }

    .title-line {
      display: flex;
      margin-bottom: 10px;

      @media (max-width: 360px) {
        display: block;
      }

      .title {
        font-weight: 600;
        margin-right: 10px;
        max-width: 405px;
      }

      .posted-at {
        font-weight: 500;
        font-size: 14px;
        color: rgba(25, 34, 29, 0.5);
        white-space: nowrap;
      }

    }

    .incident-date {
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
      margin-bottom: 12px;

      .incident-date-time {
        color: rgba(25, 34, 29, 0.5);
      }
    }
    .IncidentStatus {
      margin-bottom: 15px;
    }
  }
</style>
